<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="审批配置" name="first">
        <el-form v-model="approvalForm">
          <el-form-item label="审批场景选择">
            <Select
              v-model="approvalForm.approvalType"
              :select-options="_getAllCommodityDict('APPROVAL_CONFIG_TYPE')"
              :configuration="optionsConfig"
              clearable
              :disabled="!disableFlag"
              @change="change"
            />
          </el-form-item>
        </el-form>
        <el-button
          v-if="tableData.length < 8"
          class="mb-3"
          type="primary"
          :disabled="disableFlag"
          @click="handleAdd()"
        >
          新增行</el-button>
        <el-table
          :data="tableData"
          style="width: 100%"
          align="center"
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="节点顺序" width="80" />
          <el-table-column min-width="140" show-overflow-tooltip>
            <template slot="header" slot-scope="{}">
              <span>固定用户配置</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i
                  class="el-icon-question"
                  style="font-size: 14px; vertical-align: middle"
                />
                <div slot="content">
                  <p>
                    固定用户配置：配置后会将单个固定用户纳入审批/通知/抄送流程
                  </p>
                </div>
              </el-tooltip>
            </template>
            <el-table-column prop="fixedApproval" label="审批" min-width="120">
              <template slot-scope="scope">
                <Select
                  v-model="scope.row.fixedApproval"
                  api-key="newUserList"
                  clearable
                  multiple
                  :collapse-tags="false"
                  :disabled="disableFlag"
                />
              </template>
            </el-table-column>
            <el-table-column prop="fixedCC" label="抄送" min-width="120">
              <template slot-scope="scope">
                <Select
                  v-model="scope.row.fixedCC"
                  :disabled="disableFlag"
                  api-key="newUserList"
                  clearable
                  multiple
                  :collapse-tags="false"
                />
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column>
            <template slot="header" slot-scope="{}">
              <span>角色配置</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i
                  class="el-icon-question"
                  style="font-size: 14px; vertical-align: middle"
                />
                <div slot="content">
                  <p>角色配置：配置后会根据单据信息，</p>
                  <p>匹配负责人配置中相应负责人进行审批/通知/抄送</p>
                </div>
              </el-tooltip>
            </template>
            <el-table-column label="审批" width="165">
              <template slot-scope="scope">
                <el-row :gutter="10">
                  <el-col :span="20">
                    <el-tag
                      v-for="(item, index) in scope.row.roleApproval"
                      :key="index"
                      style="display: block"
                      class="mb-2"
                      size="small"
                      :closable="!disableFlag"
                      @click="
                        showTag(item, scope.$index, index, 'roleApproval')
                      "
                      @close="
                        handleClose(scope.row.roleApproval, item.principalName)
                      "
                    >
                      <template>
                        <el-tooltip
                          effect="dark"
                          placement="top"
                          popper-class="atooltip"
                        >
                          <div slot="content">
                            角色：{{ item.principalName }} <br>匹配条件：
                            <span
                              v-if="item.platformFlag"
                              class="mr-1"
                            >平台</span>
                            <span v-if="item.siteFlag" class="mr-1">站点</span>
                            <span
                              v-if="item.styleFlag"
                              class="mr-1"
                            >Style</span>
                            <span
                              v-if="
                                ['8', '9', '10'].includes(
                                  item.principalType + ''
                                )
                              "
                              class="mr-1"
                            >供应商</span>
                            <span v-if="item.buFlag" class="mr-1">BU</span>
                          </div>
                          <span>{{ item.principalName }}</span>
                        </el-tooltip>
                      </template>
                    </el-tag>
                  </el-col>
                  <el-col :span="4">
                    <i
                      v-if="!disableFlag"
                      class="el-icon-circle-plus-outline"
                      @click="handleAddPrincipal(scope.$index, 'roleApproval')"
                    />
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column label="抄送" width="165">
              <template slot-scope="scope">
                <el-row :gutter="10">
                  <el-col :span="20">
                    <el-tag
                      v-for="(item, index) in scope.row.roleCC"
                      :key="index"
                      style="display: block"
                      class="mb-2"
                      :closable="!disableFlag"
                      size="small"
                      @click="showTag(item, scope.$index, index, 'roleCC')"
                      @close="handleClose(scope.row.roleCC, item.principalName)"
                    >
                      <template>
                        <el-tooltip
                          effect="dark"
                          placement="top"
                          popper-class="atooltip"
                        >
                          <div slot="content">
                            角色：{{ item.principalName }} <br>匹配条件：
                            <span
                              v-if="item.platformFlag"
                              class="mr-1"
                            >平台</span>
                            <span v-if="item.siteFlag" class="mr-1">站点</span>
                            <span
                              v-if="item.styleFlag"
                              class="mr-1"
                            >Style</span>
                            <span
                              v-if="
                                ['8', '9', '10'].includes(
                                  item.principalType + ''
                                )
                              "
                              class="mr-1"
                            >供应商</span>
                          </div>
                          <span>{{ item.principalName }}</span>
                        </el-tooltip>
                      </template>
                    </el-tag>
                  </el-col>
                  <el-col :span="4">
                    <i
                      v-if="!disableFlag"
                      class="el-icon-circle-plus-outline"
                      @click="handleAddPrincipal(scope.$index, 'roleCC')"
                    />
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column>
            <template slot="header" slot-scope="{}">
              <span>例外用户配置</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i
                  class="el-icon-question"
                  style="font-size: 14px; vertical-align: middle"
                />
                <div slot="content">
                  <p>
                    例外用户配置：配置后会将用户排除在审批/通知/抄送流程外，
                  </p>
                  <p>在当前节点无法收到审批/抄送/通知流程</p>
                </div>
              </el-tooltip>
            </template>
            <el-table-column prop="exceptApproval" label="审批" min-width="120">
              <template slot-scope="scope">
                <Select
                  v-model="scope.row.exceptApproval"
                  api-key="newUserList"
                  clearable
                  multiple
                  :disabled="disableFlag"
                  :collapse-tags="false"
                />
              </template>
            </el-table-column>
            <el-table-column prop="exceptCC" label="抄送" min-width="120">
              <template slot-scope="scope">
                <Select
                  v-model="scope.row.exceptCC"
                  :disabled="disableFlag"
                  api-key="newUserList"
                  clearable
                  multiple
                  :collapse-tags="false"
                />
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column prop="name" label="抄送发起人" min-width="90">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.ccFlag" :disabled="disableFlag" />
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            min-width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                :disabled="disableFlag"
                @click="handleDelete(scope.$index)"
              >删除行
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="通知配置" name="second">
        <el-form v-model="noticeForm">
          <el-form-item label="通知功能节点选择">
            <Select
              v-model="noticeForm.noticeType"
              :select-options="_getAllCommodityDict('NOTICE_CONFIG_TYPE')"
              :configuration="optionsConfig"
              clearable
              :disabled="!disableFlag"
              @change="changeNotice"
            />
          </el-form-item>
        </el-form>
        <el-table :data="tableNoticeData" style="width: 100%">
          <el-table-column prop="fixedCC" label="固定用户配置">
            <template slot="header" slot-scope="{}">
              <span>固定用户配置</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i
                  class="el-icon-question"
                  style="font-size: 14px; vertical-align: middle"
                />
                <div slot="content">
                  <p>
                    固定用户配置：配置后会将单个固定用户纳入审批/通知/抄送流程
                  </p>
                </div>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <Select
                v-model="scope.row.fixedCC"
                :disabled="disableFlag || noticeForm.noticeType === '16'"
                api-key="newUserList"
                clearable
                multiple
                :collapse-tags="false"
              />
            </template>
          </el-table-column>
          <el-table-column prop="roleApproval" label="角色配置">
            <template slot="header" slot-scope="{}">
              <span>角色配置</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i
                  class="el-icon-question"
                  style="font-size: 14px; vertical-align: middle"
                />
                <div slot="content">
                  <p>角色配置：配置后会根据单据信息，</p>
                  <p>匹配负责人配置中相应负责人进行审批/通知/抄送</p>
                </div>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-row :gutter="10">
                <el-col :span="20">
                  <el-tag
                    v-for="(item, index) in scope.row.roleApproval"
                    :key="index"
                    style="display: block"
                    class="mb-2"
                    :closable="!disableFlag"
                    size="small"
                    @click="showTag(item, scope.$index, index, 'roleApproval')"
                    @close="
                      handleClose(scope.row.roleApproval, item.principalName)
                    "
                  >
                    <template>
                      <el-tooltip
                        effect="dark"
                        placement="top"
                        popper-class="atooltip"
                      >
                        <div slot="content">
                          角色：{{ item.principalName }} <br>匹配条件：
                          <span
                            v-if="item.platformFlag"
                            class="mr-1"
                          >平台</span>
                          <span v-if="item.siteFlag" class="mr-1">站点</span>
                          <span v-if="item.styleFlag" class="mr-1">Style</span>
                          <span
                            v-if="
                              ['8', '9', '10'].includes(item.principalType + '')
                            "
                            class="mr-1"
                          >供应商</span>
                        </div>
                        <span>{{ item.principalName }}</span>
                      </el-tooltip>
                    </template>
                  </el-tag>
                </el-col>
                <el-col :span="4">
                  <i
                    v-if="!disableFlag"
                    class="el-icon-circle-plus-outline"
                    @click="handleAddPrincipal(scope.$index, 'roleApproval')"
                  />
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column prop="exceptCC" label="例外用户配置">
            <template slot="header" slot-scope="{}">
              <span>例外用户配置</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i
                  class="el-icon-question"
                  style="font-size: 14px; vertical-align: middle"
                />
                <div slot="content">
                  <p>
                    例外用户配置：配置后会将用户排除在审批/通知/抄送流程外，
                  </p>
                  <p>在当前节点无法收到审批/抄送/通知流程</p>
                </div>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <Select
                v-model="scope.row.exceptCC"
                :disabled="disableFlag"
                api-key="newUserList"
                clearable
                multiple
                :collapse-tags="false"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :title="editForm.title"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        label-width="80px"
        :rules="editRules"
      >
        <el-form-item label="角色" prop="principalType">
          <Select
            ref="role"
            v-model="editForm.principalType"
            :select-options="roleList"
            :configuration="optionsConfig"
            clearable
            @change="handleChange"
          />
        </el-form-item>
        <el-form-item label="匹配条件">
          <el-checkbox
            v-if="
              editForm.principalType &&
                ['8', '9', '10'].includes(editForm.principalType)
            "
            v-model="editForm.vendorFlag"
            disabled
          >供应商</el-checkbox>
          <el-checkbox
            v-else-if="
              editForm.principalType &&
                ['7'].includes(editForm.principalType) &&
                (approvalForm.approvalType === '1' ||
                ['2', '3', '6'].includes(noticeForm.noticeType))
            "
            v-model="editForm.buFlag"
            disabled
          >BU</el-checkbox>
          <div
            v-else-if="
              editForm.principalType && ['11'].includes(editForm.principalType)
            "
          />
          <div v-else>
            <el-checkbox v-model="editForm.platformFlag">平台</el-checkbox>
            <el-checkbox v-model="editForm.siteFlag">站点</el-checkbox>
            <el-checkbox v-model="editForm.styleFlag">style</el-checkbox>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="editForm.title === '添加角色'"
          type="primary"
          @click="addSure"
        >确 定</el-button>
        <el-button v-else type="primary" @click="editSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-row class="mt-4">
      <el-col :offset="10" :span="4">
        <el-button :disabled="!disableFlag" @click="handleEdit">修改</el-button>
        <el-button
          :disabled="disableFlag"
          type="primary"
          :loading="saveLoading"
          @click="handleSubmit"
        >
          保存</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { Mixin } from '@/mixin/mixin.js'
import Select from '@/components/Selection'
import {
  approval,
  notice,
  upsertApproval,
  upsertNotice,
  roleSelect
} from '@/api/configuration'
export default {
  components: {
    Select
  },
  mixins: [commodityInfoDict, Mixin],
  data() {
    return {
      roleArray: [],
      arr: [],
      params: [],
      saveLoading: false,
      disableFlag: true,
      type: '',
      dialogVisible: false,
      editRules: {
        principalType: { required: true, message: '必填', trigger: 'change' }
      },
      editForm: { principalType: '' },
      tableNoticeData: [],
      tableData: [],
      activeName: 'first',
      approvalForm: { approvalType: '' },
      noticeForm: { noticeType: '' },
      optionsConfig: { key: 'val', label: 'label', value: 'val' }
    }
  },
  computed: {
    contractFlag() {
      return [8, 9, 10].includes(Number(this.approvalForm.approvalType))
    },
    roleList() {
      let arr = []
      const ROLE_FILTER = ['8', '9', '10', '11']
      const DEFAULT_TYPE = 'PRINCIPAL_CONFIG_TYPE'
      const defaultList = this._getAllCommodityDict(DEFAULT_TYPE)
      const newList = defaultList.map(({ label, val }) => ({ label, val }))
      const getFilteredRoles = (types = []) =>
        newList.filter(item => types.includes(item.val))
      const { approvalType = '' } = this.approvalForm
      const { noticeType = '' } = this.noticeForm
      if (this.activeName === 'first') {
        const typeToRolesMap = {
          '16': ROLE_FILTER,
          '17': ROLE_FILTER,
          '23': ROLE_FILTER,
          '24': ROLE_FILTER,
          '14': ['8', '11'],
          '15': ['8', '11'],
          '8': ['11'],
          '9': ['11'],
          '10': ['11'],
          '12': ['11']
        }

        arr = getFilteredRoles(typeToRolesMap[approvalType])?.length
          ? getFilteredRoles(typeToRolesMap[approvalType])
          : newList
      } else {
        const typeToRolesMap = {
          '8': ROLE_FILTER,
          '15': ['8'],
          '16': ['8']
        }
        arr = getFilteredRoles(typeToRolesMap[noticeType])?.length
          ? getFilteredRoles(typeToRolesMap[noticeType])
          : newList
      }
      arr = this.roleArray.concat(arr)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.arr = arr
      return arr.reverse()
    },
    saveNoticeParams() {
      const detail = []
      const {
        roleApproval = [],
        fixedCC = [],
        exceptCC = []
      } = this.tableNoticeData[0]
      if (roleApproval.length) {
        const principalList = roleApproval.map(v => {
          if (v.principalType.includes('QMS')) {
            v.principalType = v.principalType.split('_')[1]
          }
          return { ...v, principalType: v.principalType }
        })
        detail.push({
          configType: 2,
          principalList: principalList
        })
      }
      if (fixedCC.length) {
        detail.push({ configType: 1, usernameList: fixedCC })
      }
      if (exceptCC.length) {
        detail.push({ configType: 3, usernameList: exceptCC })
      }
      return { detail, noticeType: this.noticeForm.noticeType }
    },
    saveParams() {
      let detail = []

      const nodes = this.tableData.map((item, index) => {
        detail = []
        const {
          roleApproval = [],
          roleCC = [],
          // roleSys = [],
          fixedApproval = [],
          fixedCC = [],
          exceptCC = [],
          exceptApproval = []
        } = item

        if (fixedApproval.length) {
          detail.push({
            configType: 1,
            operationType: 1,
            usernameList: fixedApproval
          })
        }
        if (fixedCC.length) {
          detail.push({
            configType: 1,
            operationType: 2,
            usernameList: fixedCC
          })
        }
        if (exceptApproval.length) {
          detail.push({
            configType: 3,
            operationType: 1,
            usernameList: exceptApproval
          })
        }
        if (exceptCC.length) {
          detail.push({
            configType: 3,
            operationType: 2,
            usernameList: exceptCC
          })
        }
        if (roleApproval.length) {
          const principalList = roleApproval.map(v => {
            if (v.principalType.includes('QMS')) {
              v.principalType = v.principalType.split('_')[1]
            }
            return { ...v, principalType: v.principalType }
          })
          detail.push({
            configType: 2,
            operationType: 1,
            principalList: principalList
          })
        }
        if (roleCC.length) {
          const principalList = roleCC.map(v => {
            if (v.principalType.includes('QMS')) {
              v.principalType = v.principalType.split('_')[1]
            }
            return { ...v, principalType: v.principalType }
          })
          detail.push({
            configType: 2,
            operationType: 2,
            principalList: principalList
          })
        }

        return { ccFlag: item.ccFlag, detail, nodeId: index + 1 }
      })
      return Object.assign(
        {},
        { approvalType: this.approvalForm.approvalType, nodes }
      )
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.editForm && this.$refs.editForm.clearValidate()
        }, 30)
      }
    }
  },
  mounted() {
    this.getroleSelect()
  },
  methods: {
    handleTabClick() {
      this.disableFlag = true
    },
    handleEdit() {
      if (this.activeName === 'first' && !this.approvalForm.approvalType) {
        this.$message.warning('请先选择审批场景')
        return
      }
      if (this.activeName === 'second' && !this.noticeForm.noticeType) {
        this.$message.warning('请先选择通知功能节点')
        return
      }
      this.disableFlag = false
    },
    async handleNoticeSave() {
      try {
        this.saveLoading = true
        const { code, msg } = await upsertNotice(this.saveNoticeParams)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.disableFlag = true
        }
      } finally {
        this.saveLoading = false
      }
    },
    handleSubmit() {
      this.activeName === 'first' ? this.handleSave() : this.handleNoticeSave()
    },
    async handleSave() {
      if (
        this.tableData.length &&
        this.tableData.every(
          item =>
            (item.fixedApproval && item.fixedApproval.length) ||
            (item.roleApproval && item.roleApproval.length)
        )
      ) {
        try {
          this.saveLoading = true
          const { code, msg } = await upsertApproval(this.saveParams)
          if (code === 0) {
            this.$notify({
              message: msg,
              type: 'success'
            })
            this.disableFlag = true
          }
          console.log(this.saveParams)
        } finally {
          this.saveLoading = false
        }
      } else {
        this.$message.warning('请先维护审批数据')
        return
      }
    },
    handleChange(val) {
      if (['8', '9', '10'].includes(val)) {
        this.editForm.vendorFlag = true
      }
      if (['7'].includes(val)) {
        this.editForm.buFlag = true
      }
      this.editForm.platformFlag = false
      this.editForm.siteFlag = false
      this.editForm.styleFlag = false
      this.$set(
        this.editForm,
        'principalName',
        this.arr.find(item => item.val === val)?.label
      )
      // console.log(333, this._getAllCommodityDict('PRINCIPAL_CONFIG_TYPE'))
      this.$set(
        this.editForm,
        'roleSys',
        this.roleArray.find(item => item.val === val) ? 'QMS' : 'DEFAULT'
      )
    },
    handleAddPrincipal(nodeId, type) {
      this.type = type
      this.editForm = Object.assign(
        {},
        {
          title: '添加角色',
          nodeId,
          principalType: '',
          platformFlag: false,
          siteFlag: false,
          styleFlag: false,
          vendorFlag: false,
          buFlag: false
        }
      )
      this.dialogVisible = true
    },
    addSure() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          let roleApproval = []

          if (this.activeName === 'first') {
            roleApproval = this.tableData.find(
              (item, index) => index === this.editForm.nodeId
            )?.[this.type]
          } else {
            roleApproval = this.tableNoticeData[0].roleApproval
          }
          if (
            roleApproval.find(
              item => item.principalType === this.editForm.principalType
            )
          ) {
            this.$message.warning('存在重复角色配置，请核对')
            return
          }
          roleApproval.push(this.editForm)
          this.dialogVisible = false
        }
      })
    },
    // 对比两个对象的值是否完全相等 返回值 true/false
    isObjectValueEqual(a, b) {
      // 取对象a和b的属性名
      var aProps = Object.getOwnPropertyNames(a)
      var bProps = Object.getOwnPropertyNames(b)
      // 判断属性名的length是否一致
      if (aProps.length !== bProps.length) {
        return false
      }
      // 循环取出属性名，再判断属性值是否一致
      for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i]
        if (a[propName] !== b[propName]) {
          return false
        }
      }
      return true
    },
    editSure() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          const {
            nodeId,
            index: i,
            principalName,
            principalType,
            platformFlag,
            siteFlag,
            styleFlag
          } = this.editForm
          let obj = {}
          let arr = []
          let filterArr = []

          if (this.activeName === 'first') {
            arr = this.tableData.find((item, index) => index === nodeId)?.[
              this.type
            ]
            obj = arr.find((item, index) => index === i)
          } else {
            arr = this.tableNoticeData[0].roleApproval
            obj = arr.find((item, index) => index === i)
          }
          filterArr = arr.filter((item, index) => index !== i)
          // 未修改/重复角色 不允许提交
          const a = {
            principalType: obj.principalType,
            platformFlag: obj.platformFlag,
            siteFlag: obj.siteFlag,
            styleFlag: obj.styleFlag
          }
          const b = { principalType, platformFlag, siteFlag, styleFlag }
          if (
            this.isObjectValueEqual(a, b) ||
            filterArr.some(item => item.principalType === principalType)
          ) {
            this.$message.warning('存在重复角色配置，请核对')
            return
          }
          if (obj.principalName) {
            if (principalType === '8') {
              this.$set(obj, 'vendorFlag', true)
              this.$set(obj, 'platformFlag', false)
              this.$set(obj, 'siteFlag', false)
              this.$set(obj, 'styleFlag', false)
              this.$set(obj, 'buFlag', false)
            } else if (principalType === '7') {
              this.$set(obj, 'vendorFlag', false)
              this.$set(obj, 'platformFlag', false)
              this.$set(obj, 'siteFlag', false)
              this.$set(obj, 'styleFlag', false)
              this.$set(obj, 'buFlag', true)
            } else {
              this.$set(obj, 'platformFlag', platformFlag)
              this.$set(obj, 'siteFlag', siteFlag)
              this.$set(obj, 'styleFlag', styleFlag)
              this.$set(obj, 'vendorFlag', false)
              this.$set(obj, 'buFlag', false)
            }
            this.$set(obj, 'principalName', principalName)
            this.$set(obj, 'principalType', principalType)
          }
          this.dialogVisible = false
        }
      })
    },
    handleClose(arr, tag) {
      const index = arr.findIndex(item => item.principalName === tag)
      arr.splice(index, 1)
    },
    showTag(item, nodeId, index, type) {
      if (!this.disableFlag) {
        this.type = type
        this.dialogVisible = true
        this.editForm = Object.assign({}, item, {
          title: '修改角色',
          nodeId,
          index
        })
      }
    },
    handleAdd() {
      this.tableData.push({
        role: '',
        exceptCC: [],
        exceptApproval: [],
        fixedApproval: [],
        fixedCC: [],
        roleApproval: [],
        roleCC: [],
        roleSys: [],
        ccFlag: false
      })
    },
    handleDelete(index) {
      this.$confirm('此操作将删除该行, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.tableData.splice(index, 1)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    async changeNotice(val) {
      const { datas } = await notice({ noticeType: val })
      // datas即detail
      const obj = { exceptCC: [], roleApproval: [], fixedCC: [] }
      datas.map(item => {
        if (item.configType === 1) {
          this.$set(obj, 'fixedCC', item.usernameList)
        }
        if (item.configType === 2) {
          item.principalList = item.principalList.map(item => {
            if (item.roleSys === 'QMS') {
              item.principalType = 'QMS_' + item.principalType
            } else {
              item.principalType = String(item.principalType)
            }
            const principalName = this._getAllCommodityDict(
              'PRINCIPAL_CONFIG_TYPE'
            )
              .concat(this.roleArray)
              .find(e => e.val === item.principalType + '')?.label
            return {
              ...item,
              vendorFlag: item.principalType + '' === '8',
              buFlag: item.principalType + '' === '7',
              principalType: String(item.principalType),
              principalName
            }
          })
          this.$set(obj, 'roleApproval', item.principalList)
        }
        if (item.configType === 3) {
          this.$set(obj, 'exceptCC', item.usernameList)
        }
      })
      this.tableNoticeData = [obj]
    },

    async getroleSelect() {
      const { datas } = await roleSelect()
      this.roleArray = datas.map(({ roleName: label, unionId: val }) => {
        return { label, val }
      })
    },

    async change(val) {
      const { datas = [] } = await approval({ approvalType: val })
      if (!datas.length) {
        datas.push({
          ccFlag: false,
          detail: [],
          fixedApproval: [],
          fixedCC: []
        })
      }
      this.tableData = datas.map((e, i) => {
        this.$set(e, 'roleApproval', [])
        this.$set(e, 'roleCC', [])
        e.detail.map(item => {
          if (item.configType === 1) {
            item.operationType === 1
              ? this.$set(e, 'fixedApproval', item.usernameList)
              : this.$set(e, 'fixedCC', item.usernameList)
          }
          // 例外
          if (item.configType === 3) {
            item.operationType === 1
              ? this.$set(e, 'exceptApproval', item.usernameList)
              : this.$set(e, 'exceptCC', item.usernameList)
          }
          // 角色审批
          if (item.configType === 2) {
            item.principalList = item.principalList.map(item => {
              if (item.roleSys === 'QMS') {
                item.principalType = 'QMS_' + item.principalType
              } else {
                item.principalType = String(item.principalType)
              }
              const principalName = this._getAllCommodityDict(
                'PRINCIPAL_CONFIG_TYPE'
              )
                .concat(this.roleArray)
                .find(e => e.val === item.principalType + '')?.label

              // const principalList = principalList.map((a) => {
              //   if (a.roleSys = 'QMS') {
              //     a.principalType = a.principalType + 'QMS_'
              //   }
              //   return { ...a, principalType: a.principalType }
              return {
                ...item,
                principalType: String(item.principalType),
                principalName,
                vendorFlag: item.principalType + '' === '8',
                buFlag: item.principalType + '' === '7'
              }
            })
            if (item.operationType === 1) {
              this.$set(e, 'roleApproval', item.principalList)
            }
            if (item.operationType === 2) {
              this.$set(e, 'roleCC', item.principalList)
            }
            // if (item.operationType === 3) {
            //   this.$set(e, "roleSys", item.principalList);
            // }
          }
        })
        return { ...e, nodeId: i + 1 }
      })
    }
  }
}
</script>

<style>
.atooltip.el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #fff;
}
.atooltip.el-tooltip__popper[x-placement^="top"] .popper__arrow:after {
  border-top-color: #fff;
}
.atooltip {
  background: #fff !important;
  color: #000 !important;
}
</style>
